<template>
  <div class="container-main-content">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Thay đổi mật khẩu</h4>
        <div class="content-inner__body-form">
          <ValidationObserver ref="form">
            <form @keyup.enter="handleChangePassword">
              <ValidationProvider
                name="Mật khẩu hiện tại"
                vid="oldPassword"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    v-model.trim="form.oldPassword"
                    type="password"
                    class="form-control"
                    id="floatingOldPassword"
                    placeholder="name@example.com"
                  />
                  <label for="floatingOldPassword">Mật khẩu hiện tại</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Mật khẩu mới"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    v-model.trim="form.newPassword"
                    type="password"
                    class="form-control"
                    id="floatingNewPassword"
                    placeholder="name@example.com"
                  />
                  <label for="floatingNewPassword">Mật khẩu mới</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Nhập lại mật khẩu mới"
                rules="required|password:@Mật khẩu mới"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    v-model.trim="form.newPasswordConfirm"
                    type="password"
                    class="form-control"
                    id="floatingNewPasswordConfirm"
                    placeholder="name@example.com"
                  />
                  <label for="floatingNewPasswordConfirm">
                    Nhập lại mật khẩu mới
                  </label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
          <button
            type="button"
            class="btn btn-primary mb-4"
            @click="handleChangePassword"
          >
            XÁC NHẬN
          </button>
        </div>
      </div>
      <Footer />
    </div>

    <b-modal
      v-model="showModalSuccess"
      id="bv-modal-example"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <h5 class="fw600">Thay đổi mật khẩu thành công</h5>
        <span class="f14 text-second-color"
          >Mật khẩu của bạn đã được thay đổi.
        </span>
      </div>
      <b-button variant="primary" class="mt-3 w-100" @click="handleConfirm">
        Xác nhận
      </b-button>
    </b-modal>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      token: this.$cookies.get("token"),
      form: {},
      loading: {
        isLoading: false,
        fullPage: true,
      },
      showModalSuccess: false,
    };
  },
  methods: {
    handleChangePassword() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            this.loading.isLoading = true;
            const { oldPassword, newPassword } = this.form;
            const data = {
              oldPassword,
              newPassword,
            };
            const response = await UserService.changePassword(this.token, data);
            if (response.code === "SUCCESS") {
              this.showModalSuccess = true;
            } else if (response.data.code === "INVALID_ARGUMENT") {
              this.$refs.form.setErrors({
                oldPassword: "Mật khẩu hiện tại không đúng",
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
    handleConfirm() {
      const { accountRedirectUri } = this.$route.query;
      if (accountRedirectUri) {
        window.location.href = accountRedirectUri;
      } else {
        this.showModalSuccess = false;
      }
    },
  },
};
</script>
